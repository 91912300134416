import {
  CasePurpose,
  CustomerAdditionalChargeType,
  CustomerAdditionalIncomeType,
  MaritalStatus,
  MarriageType,
} from "@prisma/client";
import { type Answer } from "./interfaces";

export enum CasePurposeTranslation {
  purchaseNewAsset = "Achat immobilier",
  renovate = "Faire des travaux",
  debtReduction = "Réduire mon endettement",
  investment = "Investir",
  liquidity = "Disposer de liquidités",
  associatedCurrentAccountContribution = "Apport de compte courant d'associé",
  financeAcquisition = "Financer une acquisition / travaux / croissance",
  financeSelfSale = "Financer une vente à soi-même",
  repayCurrentAccount = "Rembourser un compte courant d'associé",
}

export const casePurposeMapping: Record<CasePurpose, CasePurposeTranslation> = {
  [CasePurpose.purchase_new_asset]: CasePurposeTranslation.purchaseNewAsset,
  [CasePurpose.renovate]: CasePurposeTranslation.renovate,
  [CasePurpose.debt_reduction]: CasePurposeTranslation.debtReduction,
  [CasePurpose.investment]: CasePurposeTranslation.investment,
  [CasePurpose.liquidity]: CasePurposeTranslation.liquidity,
  [CasePurpose.associated_current_account_contribution]:
    CasePurposeTranslation.associatedCurrentAccountContribution,
  [CasePurpose.finance_acquisition]: CasePurposeTranslation.financeAcquisition,
  [CasePurpose.finance_self_sale]: CasePurposeTranslation.financeSelfSale,
  [CasePurpose.repay_current_account]:
    CasePurposeTranslation.repayCurrentAccount,
};

export enum OnboardingMaritalStatus {
  Married = "Marié",
  Single = "Célibataire",
  Pacs = "Pacsé",
  Cohabitation = "Concubinage",
}

export const onboardingMaritalStatusMapping: Record<
  MaritalStatus,
  OnboardingMaritalStatus
> = {
  [MaritalStatus.married]: OnboardingMaritalStatus.Married,
  [MaritalStatus.single]: OnboardingMaritalStatus.Single,
  [MaritalStatus.cohabitation]: OnboardingMaritalStatus.Cohabitation,
  [MaritalStatus.pacs]: OnboardingMaritalStatus.Pacs,
};

export enum OnboardingAdditionalChargeType {
  Alimony = "Pension alimentaire",
  Other = "Autre",
}

export const onboardingAdditionalChargeTypeMapping: Record<
  CustomerAdditionalChargeType,
  OnboardingAdditionalChargeType
> = {
  [CustomerAdditionalChargeType.alimony]:
    OnboardingAdditionalChargeType.Alimony,
  [CustomerAdditionalChargeType.other]: OnboardingAdditionalChargeType.Other,
};

export enum OnboardingMarriageType {
  LegalCommunity = "Communauté légale",
  Separation = "Séparation de biens",
  UniversalCommunity = "Communauté de biens universelle",
  Contract = "Contrat de mariage",
  Other = "Autre",
}

export const onboardingMarriageTypeMapping: Record<
  MarriageType,
  OnboardingMarriageType
> = {
  [MarriageType.communaute_legale]: OnboardingMarriageType.LegalCommunity,
  [MarriageType.separation_de_biens]: OnboardingMarriageType.Separation,
  [MarriageType.communaute_de_biens_universelle]:
    OnboardingMarriageType.UniversalCommunity,
  [MarriageType.participation_aux_acquets]: OnboardingMarriageType.Contract,
  [MarriageType.autre]: OnboardingMarriageType.Other,
};

export enum OnboardingAdditionalIncomeType {
  Revenu_foncier = "Revenu foncier",
  Produit_de_placement = "Produit de placement",
  Pension = "Pension",
  Variable = "Variable",
  Allocation_CAF = "Allocation CAF",
}
export const onboardingAdditionalIncomeType: Record<
  CustomerAdditionalIncomeType,
  OnboardingAdditionalIncomeType
> = {
  [CustomerAdditionalIncomeType.other_rent]:
    OnboardingAdditionalIncomeType.Revenu_foncier,
  [CustomerAdditionalIncomeType.investment]:
    OnboardingAdditionalIncomeType.Produit_de_placement,
  [CustomerAdditionalIncomeType.pension]:
    OnboardingAdditionalIncomeType.Pension,
  [CustomerAdditionalIncomeType.variable]:
    OnboardingAdditionalIncomeType.Variable,
  [CustomerAdditionalIncomeType.caf]:
    OnboardingAdditionalIncomeType.Allocation_CAF,
};

export type CategorySlug =
  | "case"
  | "real-estate-patrimony"
  | "situation"
  | "revenues"
  | "charges"
  | "patrimony"
  | "eligibility"
  | "company-info"
  | "company-info-extra";

export type Category = {
  slug: CategorySlug;
  name: string;
  iterations?: (answers: Answer[]) => number;
  shouldDisplayIterationInfo?: "co-customer" | "asset-count";
};
