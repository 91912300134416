import { CasePurpose, CompanyType, TaxType } from "@prisma/client";
import { type QuestionOption } from "~/modules/onboarding/interfaces";
import { casePurposeMapping } from "~/modules/onboarding/mappings";

// CASE
export const casePurposeOptions: QuestionOption[] = [
  {
    label: casePurposeMapping[CasePurpose.repay_current_account],
    value: CasePurpose.repay_current_account,
  },
  {
    label:
      casePurposeMapping[CasePurpose.associated_current_account_contribution],
    value: CasePurpose.associated_current_account_contribution,
  },
  {
    label: casePurposeMapping[CasePurpose.finance_acquisition],
    value: CasePurpose.finance_acquisition,
  },
];

export const caseTypeOptions: QuestionOption[] = [
  {
    label: "Personne physique",
    value: "solo",
  },
  {
    label: "Société patrimoniale",
    value: CompanyType.patrimony,
  },
  {
    label: "Société d’exploitation commerciale",
    value: CompanyType.commercial,
  },
];

// COMPANY
export const companyTypeOptions: QuestionOption[] = [
  {
    label: "IS",
    value: TaxType.is,
  },
  {
    label: "IR",
    value: TaxType.ir,
  },
];
