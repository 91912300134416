import { type AppSvg } from "~/modules/common/interfaces";

export const IconUser: AppSvg = ({ ...rest }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.4329 13.2517C13.481 11.6061 12.0142 10.4261 10.3023 9.8667C11.1491 9.36261 11.8069 8.5945 12.1749 7.68032C12.5429 6.76614 12.6006 5.75645 12.3392 4.8063C12.0778 3.85615 11.5117 3.01807 10.7279 2.42078C9.94408 1.82349 8.98587 1.5 8.00041 1.5C7.01496 1.5 6.05675 1.82349 5.27293 2.42078C4.48911 3.01807 3.92304 3.85615 3.66163 4.8063C3.40022 5.75645 3.45793 6.76614 3.82591 7.68032C4.19388 8.5945 4.85177 9.36261 5.69854 9.8667C3.98666 10.4255 2.51979 11.6055 1.56791 13.2517C1.53301 13.3086 1.50985 13.372 1.49982 13.438C1.48978 13.504 1.49307 13.5713 1.50949 13.636C1.52591 13.7008 1.55512 13.7615 1.59541 13.8148C1.63569 13.868 1.68624 13.9127 1.74405 13.9461C1.80187 13.9795 1.86579 14.0009 1.93204 14.0092C1.9983 14.0175 2.06554 14.0125 2.1298 13.9943C2.19407 13.9762 2.25405 13.9454 2.30622 13.9038C2.35838 13.8621 2.40168 13.8104 2.43354 13.7517C3.61104 11.7167 5.69229 10.5017 8.00041 10.5017C10.3085 10.5017 12.3898 11.7167 13.5673 13.7517C13.5992 13.8104 13.6424 13.8621 13.6946 13.9038C13.7468 13.9454 13.8068 13.9762 13.871 13.9943C13.9353 14.0125 14.0025 14.0175 14.0688 14.0092C14.135 14.0009 14.199 13.9795 14.2568 13.9461C14.3146 13.9127 14.3651 13.868 14.4054 13.8148C14.4457 13.7615 14.4749 13.7008 14.4913 13.636C14.5078 13.5713 14.511 13.504 14.501 13.438C14.491 13.372 14.4678 13.3086 14.4329 13.2517ZM4.50041 6.0017C4.50041 5.30947 4.70568 4.63278 5.09027 4.0572C5.47485 3.48163 6.02148 3.03303 6.66102 2.76812C7.30056 2.50322 8.0043 2.4339 8.68323 2.56895C9.36216 2.704 9.9858 3.03734 10.4753 3.52683C10.9648 4.01631 11.2981 4.63995 11.4332 5.31888C11.5682 5.99782 11.4989 6.70155 11.234 7.34109C10.9691 7.98063 10.5205 8.52726 9.94491 8.91184C9.36934 9.29643 8.69265 9.5017 8.00041 9.5017C7.07246 9.50071 6.1828 9.13164 5.52664 8.47548C4.87047 7.81932 4.50141 6.92965 4.50041 6.0017Z"
        fill="#9A9C9F"
      />
    </svg>
  );
};
