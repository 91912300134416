import {
  CustomerAdditionalIncomeType,
  JobType,
  type LoanType,
} from "@prisma/client";
import { type Record } from "@prisma/client/runtime/library";
import { StringBoolean } from "~/types";

export enum JobTypeTranslation {
  Employee = "Salarié",
  SelfEmployed = "Non-salarié",
  Retired = "Retraité",
  Unemployed = "Sans emploi",
}

export const jobTypeLabels: Record<JobType, JobTypeTranslation> = {
  [JobType.employee]: JobTypeTranslation.Employee,
  [JobType.self_employed]: JobTypeTranslation.SelfEmployed,
  [JobType.retired]: JobTypeTranslation.Retired,
  [JobType.unemployed]: JobTypeTranslation.Unemployed,
};

export enum IsTrialingTranslation {
  Trialing = "En cours",
  Approved = "Validée",
}

export const isTrialingLabels: Record<StringBoolean, IsTrialingTranslation> = {
  [StringBoolean.true]: IsTrialingTranslation.Trialing,
  [StringBoolean.false]: IsTrialingTranslation.Approved,
};

export enum AdditionalIncomeTypeTranslation {
  Revenu_foncier = "Revenu foncier",
  Produit_de_placement = "Produit de placement",
  Pension = "Pension",
  Variable = "Variable",
  Allocation_CAF = "Allocation CAF",
}
export const additionalIncomeTypeLabels: Record<
  CustomerAdditionalIncomeType,
  AdditionalIncomeTypeTranslation
> = {
  [CustomerAdditionalIncomeType.other_rent]:
    AdditionalIncomeTypeTranslation.Revenu_foncier,
  [CustomerAdditionalIncomeType.investment]:
    AdditionalIncomeTypeTranslation.Produit_de_placement,
  [CustomerAdditionalIncomeType.pension]:
    AdditionalIncomeTypeTranslation.Pension,
  [CustomerAdditionalIncomeType.variable]:
    AdditionalIncomeTypeTranslation.Variable,
  [CustomerAdditionalIncomeType.caf]:
    AdditionalIncomeTypeTranslation.Allocation_CAF,
};

export const LoanTypeLabel: Record<LoanType, string> = {
  in_fine: "In fine",
  depreciable: "Amortissable",
};
