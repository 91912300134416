import { type AppSvg } from "~/modules/common/interfaces";

export const IconHouseLine: AppSvg = ({ ...rest }) => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M20.625 18.3752H19.25V12.1877L19.4511 12.3888C19.5803 12.5178 19.7555 12.5902 19.9381 12.59C20.1207 12.5899 20.2958 12.5172 20.4248 12.388C20.5538 12.2587 20.6262 12.0835 20.626 11.9009C20.6258 11.7183 20.5531 11.5433 20.4239 11.4143L11.972 2.96491C11.7141 2.70724 11.3645 2.5625 11 2.5625C10.6355 2.5625 10.2859 2.70724 10.028 2.96491L1.57609 11.4143C1.4472 11.5433 1.37484 11.7182 1.37492 11.9006C1.375 12.0829 1.44752 12.2578 1.57652 12.3867C1.70553 12.5156 1.88045 12.5879 2.0628 12.5878C2.24516 12.5878 2.42002 12.5152 2.54891 12.3862L2.75 12.1877V18.3752H1.375C1.19266 18.3752 1.0178 18.4477 0.888864 18.5766C0.759933 18.7055 0.6875 18.8804 0.6875 19.0627C0.6875 19.2451 0.759933 19.4199 0.888864 19.5489C1.0178 19.6778 1.19266 19.7502 1.375 19.7502H20.625C20.8073 19.7502 20.9822 19.6778 21.1111 19.5489C21.2401 19.4199 21.3125 19.2451 21.3125 19.0627C21.3125 18.8804 21.2401 18.7055 21.1111 18.5766C20.9822 18.4477 20.8073 18.3752 20.625 18.3752ZM4.125 10.8127L11 3.93772L17.875 10.8127V18.3752H13.75V13.5627C13.75 13.3804 13.6776 13.2055 13.5486 13.0766C13.4197 12.9477 13.2448 12.8752 13.0625 12.8752H8.9375C8.75516 12.8752 8.5803 12.9477 8.45136 13.0766C8.32243 13.2055 8.25 13.3804 8.25 13.5627V18.3752H4.125V10.8127ZM12.375 18.3752H9.625V14.2502H12.375V18.3752Z" />
    </svg>
  );
};
