import { type AppSvg } from "~/modules/common/interfaces";

export const IconHouse: AppSvg = ({ ...rest }) => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M18.847 9.83991L11.972 2.96491C11.7141 2.70724 11.3645 2.5625 11 2.5625C10.6355 2.5625 10.2859 2.70724 10.0281 2.96491L3.15306 9.83991C3.02475 9.96726 2.92305 10.1188 2.85386 10.2858C2.78467 10.4529 2.74937 10.632 2.75001 10.8127V19.0627C2.75001 19.2451 2.82244 19.4199 2.95137 19.5489C3.0803 19.6778 3.25517 19.7502 3.43751 19.7502H8.93751C9.11984 19.7502 9.29471 19.6778 9.42364 19.5489C9.55257 19.4199 9.62501 19.2451 9.62501 19.0627V14.2502H12.375V19.0627C12.375 19.2451 12.4474 19.4199 12.5764 19.5489C12.7053 19.6778 12.8802 19.7502 13.0625 19.7502H18.5625C18.7448 19.7502 18.9197 19.6778 19.0486 19.5489C19.1776 19.4199 19.25 19.2451 19.25 19.0627V10.8127C19.2507 10.632 19.2154 10.4529 19.1462 10.2858C19.077 10.1188 18.9753 9.96726 18.847 9.83991ZM17.875 18.3752H13.75V13.5627C13.75 13.3804 13.6776 13.2055 13.5486 13.0766C13.4197 12.9477 13.2448 12.8752 13.0625 12.8752H8.93751C8.75517 12.8752 8.5803 12.9477 8.45137 13.0766C8.32244 13.2055 8.25001 13.3804 8.25001 13.5627V18.3752H4.12501V10.8127L11 3.93772L17.875 10.8127V18.3752Z" />
    </svg>
  );
};
